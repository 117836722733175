import React, { useContext, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { CardContainer, CircleLoader } from '@driftt/tide-core';

import config from 'config';
import { UserContext } from 'context/UserContext';
import { UserCustomizationsContext } from 'context/UserCustomizationContext';

import AccountSettings from './accountSettings';
import BillingSettings from './billingSettings';
import ConversationSettings from './conversationSettings';
import CustomBranding from './CustomBranding';
import NotificationSettings from './notificationSettings';
import PrivacySettings from './privacySettings';

import './styles.css';

const MeetingsRedirect = () => {
  window.location.href = `${config.BASE_URL}/meetings/mine`;
  return null;
};

const Settings = () => {
  const { userCustomization, userCustomizationLoading, handleUserCustomizationChange } =
    useContext(UserCustomizationsContext);
  const { userHasFeature } = useContext(UserContext);

  useEffect(() => {
    document.title = 'Drift | Video settings';
  }, []);

  if (userCustomizationLoading) {
    return (
      <CardContainer className="loading-card-container" padding="large">
        <div className="player-loader">
          <CircleLoader size="large" />
        </div>
      </CardContainer>
    );
  } else {
    return (
      <div className="settings-wrapper">
        <div className="settings-content">
          <Route exact path="/video/settings">
            <Redirect to="/video/settings/account" />
          </Route>
          <Route path="/video/settings/account">
            <AccountSettings className="account-settings-card" />
          </Route>
          <Route path="/video/settings/notifications">
            <NotificationSettings
              className="notifications-settings-card"
              notificationSettings={userCustomization.notificationSettings}
              updateUserCustomizationsWrapper={handleUserCustomizationChange}
            />
          </Route>
          <Route path="/video/settings/privacy">
            <PrivacySettings
              className="privacy-settings-card"
              initialVideoVisibility={userCustomization.initialVideoVisibility}
              onChangeInitialVideoVisibility={(initialVideoVisibility) =>
                handleUserCustomizationChange({
                  initialVideoVisibility,
                })
              }
            />
          </Route>
          <Route path="/video/settings/meetings" render={() => <MeetingsRedirect />} />
          <Route path="/video/settings/billing">
            <BillingSettings></BillingSettings>
          </Route>
          <Route path="/video/settings/welcome-message">
            <ConversationSettings
              conversationVisibility={userCustomization.conversationVisibility}
              updateUserCustomizationsWrapper={handleUserCustomizationChange}
              hasBotEditing={userHasFeature('HAS_BOT_MESSAGE_EDITING')}
            />
          </Route>
          <Route path="/video/settings/branding">
            <CustomBranding />
          </Route>
        </div>
      </div>
    );
  }
};

export default Settings;
